import * as queryString from 'query-string';
import { generatePath } from 'react-router-dom';

class Routes {
  public static BASE_ROUTE = $appPath && $appPath.length > 0 ? '/' + $appPath : '';
  //old paths
  public static __OLD_DEFAULT = Routes.BASE_ROUTE + '/default.aspx';
  public static __OLD_PUBLIC_REPORTS = Routes.BASE_ROUTE + '/publicview';
  public static __OLD_DRSFA = Routes.BASE_ROUTE + '/drsfa/drsfa_contribution.aspx';
  public static __OLD_OTC = Routes.BASE_ROUTE + '/otc/otc_contribution.aspx';
  public static __OLD_PFD = Routes.BASE_ROUTE + '/pfd/disclosure.aspx';
  public static __OLD_SEARCHABLE_DATASET = Routes.__OLD_PUBLIC_REPORTS + '/newcontributionsearch.aspx';
  public static __OLD_INTRO = Routes.__OLD_PUBLIC_REPORTS + '/intro.aspx';
  public static __OLD_DR1 = Routes.BASE_ROUTE + '/start.aspx';
  public static __OLD_REPORTS_SEARCH = Routes.__OLD_PUBLIC_REPORTS + '/search.aspx';

  public static HOME_ROUTE = Routes.BASE_ROUTE + '/';
  public static LOGIN = Routes.BASE_ROUTE + '/welcome'
  public static PROFILE = Routes.BASE_ROUTE + '/profile';
  public static REQUEST_RESET_PASSWORD = Routes.BASE_ROUTE + '/reset-password';
  public static RESET_PASSWORD_LINK = Routes.BASE_ROUTE + '/resetPassword';
  public static REGISTER = Routes.BASE_ROUTE + '/register';
  public static CONFIRM_EMAIL = Routes.BASE_ROUTE + '/confirmEmail';

  public static PUBLIC_REPORTS = Routes.BASE_ROUTE + '/publicReports';
  public static SEARCHABLE_DATABASE = Routes.PUBLIC_REPORTS + '/searchable-database';
  public static DATASETS = Routes.PUBLIC_REPORTS + '/datasets';
  public static REPORTS_STATEMENT_OF_ORGANIZATION_AND_DISSOLUTION = Routes.PUBLIC_REPORTS + '/statement-of-organization-and-dissolution-reports';
  public static STATE_REPORTS = Routes.PUBLIC_REPORTS + '/state-reports';
  public static OTC_REPORTS = Routes.PUBLIC_REPORTS + '/one-time-contribution-reports';
  public static VSR_REPORTS = Routes.PUBLIC_REPORTS + '/verified-statement-of-registration-reports';
  public static IE_REPORTS = Routes.PUBLIC_REPORTS + '/independent-expenditure-reports';
  public static COUNTY_REPORTS = Routes.PUBLIC_REPORTS + '/county-and-local-reports';
  public static PFD_REPORTS = Routes.PUBLIC_REPORTS + '/pfd-reports';
  public static GB_REPORTS = Routes.PUBLIC_REPORTS + '/gb-reports';
  public static CAMPAIGN_LATE_REPORTS = Routes.PUBLIC_REPORTS + '/campaign-late-reports';
  public static ETHICS_LATE_REPORTS = Routes.PUBLIC_REPORTS + '/ethics-late-reports';
  public static FILTERED_LATE_REPORTS = Routes.PUBLIC_REPORTS + '/late-reports/:committeeId/:lateReportTypeId';
  public static ESCHEAT_REPORTS = Routes.PUBLIC_REPORTS + '/escheat-reports';
  public static DUAL_COMP_REPORTS = Routes.PUBLIC_REPORTS + '/dual-comp-reports';
  public static BUILDING_FUND_REPORTS = Routes.PUBLIC_REPORTS + '/building-fund-reports';

  public static DR1 = Routes.BASE_ROUTE + '/dr1';
  public static DRSFA = Routes.BASE_ROUTE + '/drsfa';
  public static OIE = Routes.BASE_ROUTE + '/oie'
  public static OTC = Routes.BASE_ROUTE + '/otc';
  public static PFD = Routes.BASE_ROUTE + '/pfd';
  public static DONOR_REGISTER = Routes.BASE_ROUTE + '/donorRegistration';
  public static IIE = Routes.BASE_ROUTE + '/iie';
  public static VSR = Routes.BASE_ROUTE + '/vsr';
  public static DUAL_COMP = Routes.BASE_ROUTE + '/dual-comp';
  public static SALES_BY_STATE = Routes.BASE_ROUTE + '/sales-by-state'
  public static PETITION_FOR_WAIVER = Routes.BASE_ROUTE + '/petitionWaiver';

  public static RESEND_CONFIRMATION_EMAIL = Routes.BASE_ROUTE + '/resend-confirmation-email';

  public static UNAUTHORIZED = Routes.BASE_ROUTE + '/unauthorized';

  public static SECURITY_BASE = Routes.BASE_ROUTE + '/security';
  public static SECURITY_USERS = Routes.SECURITY_BASE + '/users';
  public static SECURITY_USER_DETAIL = Routes.SECURITY_USERS + '/:id';
  public static SECURITY_ROLEGROUPS = Routes.SECURITY_BASE + '/roleGroups';
  public static SECURITY_ROLEGROUP_DETAIL = Routes.SECURITY_ROLEGROUPS + '/:id';

  public static REPORT_VIEWER = Routes.BASE_ROUTE + '/reportView';

  public static REPORTS_BASE = Routes.BASE_ROUTE + '/reports';
  public static REPORTS_SQLREPORTS = Routes.REPORTS_BASE + '/sqlreports';
  public static REPORTS_SQLREPORT = Routes.REPORTS_SQLREPORTS + '/:id';

  public static GROUPS_BASE = Routes.BASE_ROUTE + '/groups';
  public static GROUP_DETAILS = Routes.GROUPS_BASE + '/edit/:id';

  public static ADMIN_BASE = Routes.BASE_ROUTE + '/admin';
  public static ADMIN_REVIEWACCOUNT = Routes.ADMIN_BASE + '/review';
  public static ADMIN_SEARCH = Routes.ADMIN_BASE + '/search';
  public static CERTIFY_DR3 = Routes.ADMIN_BASE + '/certifyDR3';
  public static REJECT_DR3 = Routes.CERTIFY_DR3 + '/reject';
  public static MANAGE_OTC = Routes.ADMIN_BASE + '/otc';
  public static EDIT_OTC = Routes.MANAGE_OTC + '/:id';
  public static MANUAL_REGISTRATION = Routes.ADMIN_BASE + '/manualRegistration';

  public static MANAGE_ESCHEATS = Routes.ADMIN_BASE + '/escheats';
  public static ADD_ESCHEAT = Routes.MANAGE_ESCHEATS + '/new';
  public static EDIT_ESCHEAT = Routes.MANAGE_ESCHEATS + '/:id';

  public static CONSENT_FOR_SALES_AND_LEASES_REPORTS = Routes.PUBLIC_REPORTS + '/cfsl-reports';
  public static MANAGE_CONSENT_FOR_SALES_AND_LEASES = Routes.ADMIN_BASE + '/salesAndLeases'
  public static ADD_CONSENT_FOR_SALES_AND_LEASES = Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES + '/New';
  public static EDIT_CONSENT_FOR_SALES_AND_LEASES = Routes.MANAGE_CONSENT_FOR_SALES_AND_LEASES + '/edit/:cfslId';

  public static MANAGE_DUAL_COMP =  Routes.ADMIN_BASE + '/dual-comp'
  public static EDIT_DUAL_COMP = Routes.MANAGE_DUAL_COMP + '/:id';

  public static MANAGE_PETITION_FOR_WAIVER = Routes.ADMIN_BASE + '/petitionWaiver';
  public static EDIT_PETITION_FOR_WAIVER = Routes.MANAGE_PETITION_FOR_WAIVER + '/:id';

  public static MANAGE_SALES_BY_STATE = Routes.ADMIN_BASE + '/salesByState';
  public static SALES_BY_STATE_REPORTS = Routes.PUBLIC_REPORTS + '/salesByState-reports';
  public static EDIT_SALES_BY_STATE = Routes.MANAGE_SALES_BY_STATE + '/edit/:sbsId';

  public static ADMIN_REPORTS = Routes.ADMIN_BASE + '/reports';

  public static MANAGE_USERS = Routes.ADMIN_BASE + '/users';
  public static ADD_USER = Routes.MANAGE_USERS + '/New';
  public static EDIT_USER = Routes.MANAGE_USERS + '/edit/:id';

  public static DONOR_BASE = Routes.BASE_ROUTE + '/donor';
  public static DONOR_SUMMARY = Routes.DONOR_BASE + '/summary';
  public static GIFT_BEQUEST = Routes.DONOR_BASE + '/giftBequest';
  public static EDIT_GIFT_BEQUEST = Routes.DONOR_BASE + '/editGiftBequest/:giftId';
  public static DONOR_PROFILE = Routes.DONOR_BASE + '/profile';
  
  public static OTC_PENALTY = Routes.MANAGE_OTC + '/penalty/:id';
  public static MANAGE_DRSFA = Routes.ADMIN_BASE + '/drsfa';

  public static EDIT_DRSFA = Routes.MANAGE_DRSFA + '/:drsfaId/:filingStatusType';
  public static MANAGE_GIFT_BEQUEST = Routes.ADMIN_BASE + '/giftBequest';
  public static ADMIN_EDIT_GIFT_BEQUEST = Routes.MANAGE_GIFT_BEQUEST + '/:giftId';
  public static MANAGE_DR1 = Routes.ADMIN_BASE + '/dr1';

  public static CONTACTS_BASE = Routes.BASE_ROUTE + '/contact';
  public static ADD_CONTACT = Routes.CONTACTS_BASE + '/New';
  public static EDIT_CONTACT = Routes.CONTACTS_BASE + '/edit/:perorgId';

  public static BUILDINGFUNDS_BASE = Routes.BASE_ROUTE + '/buildingFunds';
  public static BUILDINGFUNDS_SUMMARY = Routes.BUILDINGFUNDS_BASE + '/summary';
  public static BUILDINGFUNDS_HISTORY = Routes.BUILDINGFUNDS_BASE + '/history/:id';
  public static BUILDINGFUND_CONTRIBUTIONS = Routes.BUILDINGFUNDS_BASE + '/contributions/:buildingFundId/:contributionId/:year';
  public static BUILDINGFUND_EXPENDITURES = Routes.BUILDINGFUNDS_BASE + '/expenditures/:buildingFundId/:expenditureId/:year';

  public static ACCOUNT_BASE = Routes.BASE_ROUTE + '/account';

  public static ORGANIZATIONS_BASE = Routes.BASE_ROUTE + '/organization';
  public static FILER_MANAGE_USERS = Routes.ORGANIZATIONS_BASE + '/users';
  public static FILER_ADD_USER = Routes.FILER_MANAGE_USERS + '/New';
  public static FILER_EDIT_USER = Routes.FILER_MANAGE_USERS + '/edit/:id';
  public static APPROVE_DR1_EDIT = Routes.MANAGE_DR1 + '/approveDR1/:committeeId';
  public static APPROVE_DR1 = Routes.MANAGE_DR1 + '/approve';
  public static MANAGE_HELP_PAGES = Routes.ADMIN_BASE + '/help';
  public static MANAGE_IIE = Routes.ADMIN_BASE + '/iie'
  public static IIE_PENALTY = Routes.MANAGE_IIE + '/penalty/:id';
  public static EDIT_IIE = Routes.MANAGE_IIE + '/:id';
  public static INTERNAL_NOTES = Routes.ORGANIZATIONS_BASE + '/internalNotes';
  public static COMMITTEE_IMPORT = Routes.ORGANIZATIONS_BASE + '/import';
  public static SELECT_FILING = Routes.ORGANIZATIONS_BASE + '/selectFiling';

  public static PERIOD_MAIN = Routes.ADMIN_BASE + '/periodMain'
  public static PERIOD_ADD = Routes.PERIOD_MAIN + '/New';
  public static EDIT_PERIOD = Routes.PERIOD_MAIN + '/:id';

  public static MANAGE_PFD = Routes.ADMIN_BASE + '/pfd';
  public static PFD_PENALTY = Routes.MANAGE_PFD + '/penalty/:id';
  public static EDIT_PFD = Routes.MANAGE_PFD + '/edit/:id';
  public static ADD_PFD_CONTACT = Routes.MANAGE_PFD + '/contact/new';
  public static EDIT_PFD_CONTACT = Routes.MANAGE_PFD + '/contact/:id';

  public static MANAGE_OIE = Routes.ADMIN_BASE + '/oie';
  public static OIE_PENALTY = Routes.MANAGE_OIE + '/penalty/:id';
  public static EDIT_OIE = Routes.MANAGE_OIE + '/:id';

  public static SCHEDULE_BASE = Routes.BASE_ROUTE + '/schedules';
  public static SCHEDULE_SUMMARY = Routes.SCHEDULE_BASE + '/summary';
  public static DR2_SUBMIT_STEPPER = Routes.SCHEDULE_SUMMARY + '/submit/:id'
  public static DR2_SUBMIT = Routes.DR2_SUBMIT_STEPPER + '/:dr2Id';
  public static DR2_SUBMIT_COMPLETION = Routes.SCHEDULE_BASE + '/submitCompletion';
  public static SCHEDULE_A_CONTRIBUTIONS = Routes.SCHEDULE_BASE + '/schAContributions';
  public static ADD_SCHEDULE_A_CONTRIBUTION = Routes.SCHEDULE_A_CONTRIBUTIONS + '/New';
  public static EDIT_SCHEDULE_A_CONTRIBUTION = Routes.SCHEDULE_A_CONTRIBUTIONS + '/:id/:seqNum';
  public static IMPORT_SCHEDULE_A_CONTRIBUTIONS = Routes.SCHEDULE_A_CONTRIBUTIONS + '/import';
  public static IMPORT_EDIT_SCHEDULE_A_CONTRIBUTION = Routes.SCHEDULE_A_CONTRIBUTIONS + '/:batchNum/:transNum/:userId';
  public static SCHEDULE_B_EXPENDITURES = Routes.SCHEDULE_BASE + '/schBExpenditures';
  public static ADD_SCHEDULE_B_EXPENDITURE = Routes.SCHEDULE_B_EXPENDITURES + '/New';
  public static ADD_SCHEDULE_B_EXPENDITURE_FROM_DEBT = Routes.SCHEDULE_B_EXPENDITURES + '/Debt/:debtId/:seqNum';
  public static EDIT_SCHEDULE_B_EXPENDITURE = Routes.SCHEDULE_B_EXPENDITURES + '/:id/:seqNum';
  public static IMPORT_SCHEDULE_B_EXPENDITURES = Routes.SCHEDULE_B_EXPENDITURES + '/import';
  public static SCHEDULE_D_DEBTS = Routes.SCHEDULE_BASE + '/schDDebts';
  public static ADD_SCHEDULE_D_DEBT = Routes.SCHEDULE_D_DEBTS + '/New';
  public static EDIT_SCHEDULE_D_DEBT = Routes.SCHEDULE_D_DEBTS + '/:id/:seqNum';
  public static SCHEDULE_E_IN_KIND_CONTRIBUTIONS = Routes.SCHEDULE_BASE + '/schEInKindContributions';
  public static ADD_SCHEDULE_E_IN_KIND_CONTRIBUTION = Routes.SCHEDULE_E_IN_KIND_CONTRIBUTIONS + '/New';
  public static ADD_SCHEDULE_E_IN_KIND_CONTRIBUTION_FROM_DEBT = Routes.SCHEDULE_E_IN_KIND_CONTRIBUTIONS + '/Debt/:debtId/:seqNum';
  public static EDIT_SCHEDULE_E_IN_KIND_CONTRIBUTION = Routes.SCHEDULE_E_IN_KIND_CONTRIBUTIONS + '/:id/:seqNum';
  public static SCHEDULE_F1_LOANS_RECEIVED = Routes.SCHEDULE_BASE + '/schF1LoansReceived';
  public static ADD_SCHEDULE_F1_LOAN_RECEIVED = Routes.SCHEDULE_F1_LOANS_RECEIVED + '/New';
  public static EDIT_SCHEDULE_F1_LOAN_RECEIVED = Routes.SCHEDULE_F1_LOANS_RECEIVED + '/:id/:seqNum';
  public static SCHEDULE_F2_LOANS_REPAID = Routes.SCHEDULE_BASE + '/schF2LoansRepaid';
  public static ADD_SCHEDULE_F2_LOAN_REPAID = Routes.SCHEDULE_F2_LOANS_REPAID + '/New';
  public static EDIT_SCHEDULE_F2_LOAN_REPAID = Routes.SCHEDULE_F2_LOANS_REPAID + '/:id/:seqNum';
  public static SCHEDULE_G_CONSULTANTS = Routes.SCHEDULE_BASE + '/schGConsultants';
  public static ADD_SCHEDULE_G_CONSULTANT = Routes.SCHEDULE_G_CONSULTANTS + '/New';
  public static EDIT_SCHEDULE_G_CONSULTANT = Routes.SCHEDULE_G_CONSULTANTS + '/:summaryId/:summarySeqNum';
  public static SCHEDULE_G_CONSULTANT_EXPENSES = Routes.SCHEDULE_BASE + '/schGConsultantExpenses/:summaryId/:summarySeqNum';
  public static ADD_SCHEDULE_G_CONSULTANT_EXPENSE = Routes.SCHEDULE_G_CONSULTANT_EXPENSES + '/New';
  public static EDIT_SCHEDULE_G_CONSULTANT_EXPENSE = Routes.SCHEDULE_G_CONSULTANT_EXPENSES + '/:expenditureId/:expenditureSeqNum';
  public static SCHEDULE_H1_CAMPAIGN_PROPERTIES = Routes.SCHEDULE_BASE + '/schH1CampaignProperties';
  public static ADD_SCHEDULE_H1_CAMPAIGN_PROPERTY = Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES + '/New';
  public static EDIT_SCHEDULE_H1_CAMPAIGN_PROPERTY = Routes.SCHEDULE_H1_CAMPAIGN_PROPERTIES + '/:id/:seqNum';
  public static SCHEDULE_H2_PROPERTY_SALES = Routes.SCHEDULE_BASE + '/schH2PropertySales';
  public static ADD_SCHEDULE_H2_PROPERTY_SALE = Routes.SCHEDULE_H2_PROPERTY_SALES + '/New';
  public static EDIT_SCHEDULE_H2_PROPERTY_SALE = Routes.SCHEDULE_H2_PROPERTY_SALES + '/:id/:seqNum';

  public static FILING_PERIOD_HISTORY = Routes.SCHEDULE_BASE + '/history';
  public static DR2_PENALTY = Routes.SCHEDULE_BASE + '/penalty/:from';
  public static ADJUST_ENTIRE_PERIOD = Routes.SCHEDULE_BASE + '/adjustEntirePeriod';
  public static ADJUST_PERIOD_DATES = Routes.SCHEDULE_BASE + '/adjustPeriodDates';
  public static DELETE_FILING = Routes.SCHEDULE_BASE + '/deleteFiling';

  public static CONTRIBUTIONS_BASE = Routes.BASE_ROUTE + '/contributions';
  public static VSR_CONTRIBUTIONS = Routes.CONTRIBUTIONS_BASE + '/vsr';
  public static ADD_VSR_CONTRIBUTION = Routes.VSR_CONTRIBUTIONS + '/New';
  public static EDIT_VSR_CONTRIBUTION = Routes.VSR_CONTRIBUTIONS + '/:id/:seqNum';
  public static VSR_PENALTY = Routes.VSR_CONTRIBUTIONS + '/penalty/:id/:from';

  public static DISSOLUTION_BASE = Routes.BASE_ROUTE + '/dissolution';


  /**
   * Generated a url from a route and parameters.
   * @param route Route that may contain parameter placeholders.
   * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
   */
  public static generate(
    route: string,
    params?: { [paramName: string]: string | number | boolean },
    query?: { [name: string]: any }
  ) {
    let path = generatePath(route, params);

    // Add any query string variables to the route if passed
    if (query) {
      const q = queryString.stringify(query);
      if (q) {
        path += `?${q}`;
      }
    }

    return path;
  }

  public static getRoutes() {
    const routes = [];
    for (const route in this) {
      if (route.substring(0,2) !== '__') {
        routes.push(this[route]);
      }
    }
    return routes;
  }
}

export default Routes;
