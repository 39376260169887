import {
  Button,
  Col,
  ColProps,
  Divider,
  Layout,
  notification,
  Row,
  Tabs,
  Modal
} from 'antd';
import * as React from 'react';
import BuildingFundApiService from '../../api/BuildingFundApiService';
import CurrentUser from '../../utils/CurrentUser';
import FilingStatusType from '../../consts/FilingStatusType';
import BuildingFundsStatusBar from './BuildingFundStatusBar';
import BuildingFundFilingDTO from '../../models/BuildingFundFilingDTO';
import BuildingFundSummaryDescriptions from './BuildingFundSummaryInfoDescriptions';
import BuildingFundFilingForm from './BuildingFundFilingForm';
import BuildingFundContributionTable from './Contributions/BuildingFundContributionTable';
import BuildingFundExpenditureTable from './Expenditures/BuildingFundExpenditureTable';
import moment from 'moment';
import FormItem from 'antd/lib/form/FormItem';
import CustomForm from '../shared/CustomForm';
import CustomDatePicker from '../shared/CustomDatePicker';
import { RouteComponentProps } from 'react-router-dom';
const { Content } = Layout;
const { TabPane } = Tabs;

interface BuildingFundsSummaryProps {
}

interface BuildingFundsSummaryDeepState {
    year: number;
}


interface BuildingFundsSummaryState {
  summaryInfo: BuildingFundFilingDTO;
  committeeId: string;
  coverYear: moment.Moment;
  showFilingForm: boolean;
  filed: boolean;
  loading: boolean;
}

class BuildingFundsSummary extends React.Component<RouteComponentProps<BuildingFundsSummaryProps>, BuildingFundsSummaryState> {
  constructor(props: RouteComponentProps<BuildingFundsSummaryProps>) {
    super(props);
 
      const yearFromLocation = (this.props.location.state as BuildingFundsSummaryDeepState)?.year;

      this.state = {
          committeeId: CurrentUser.Get()?.committeeId || '',
          summaryInfo: BuildingFundFilingDTO.create(), 
          coverYear: moment().year(yearFromLocation || moment().year()), 
          showFilingForm: true,
          filed: true,
          loading: true,
      };
 
  }

  componentDidMount() {
    if (this.state.committeeId && this.state.coverYear) {
      this.getSummary(this.state.coverYear);
    }
  }

  render() {
    const { summaryInfo, committeeId, coverYear, showFilingForm, loading, filed } = this.state;
    const columnSizingProps: ColProps = { xs: 24, sm: 24, md: 12 };
    const selectedYear: string = coverYear.year().toString();
    return (
      <>
      { !loading &&
          <Content className="content-pad" key={committeeId}>
            <Col {...columnSizingProps}>
              <CustomForm layout="vertical" initialValues={{ coveredYear: coverYear }}>
                <FormItem label="Covered Year" name="coveredYear">
                  <CustomDatePicker
                    picker="year"
                    format="YYYY"
                    onChange={this.onCoveredYearOnChange}
                    disabledDates={(current) => this.checkDisableDate(current)}
                  />
                </FormItem>
              </CustomForm>
            </Col>
            <Divider />
            <br />
            {showFilingForm ?
              <BuildingFundFilingForm
                committeeId={committeeId}
                coveredYear={selectedYear}
                onRefresh={() => this.getSummary(coverYear)}
              />
              :
              <>
                <BuildingFundsStatusBar summaryInfo={summaryInfo} onRefresh={() => this.getSummary(coverYear)} filed={filed} />
                <Row gutter={24}>
                  <Col {...columnSizingProps}>
                    <BuildingFundSummaryDescriptions summaryInfo={summaryInfo} />
                    <br /><br />
                    {!filed &&
                      <Button type="primary"
                        onClick={() => this.fileReport(summaryInfo.buildingFundFilingId, coverYear.year())}
                      >
                        File Report Here
                      </Button>
                    }
                  </Col>
                </Row>
                <Divider />
                <Tabs size="large" tabBarGutter={24}  >
                  <TabPane tab="Contributions" key="contribution" forceRender={true}>
                    <BuildingFundContributionTable
                      buildingFundId={summaryInfo.buildingFundFilingId}
                      onRefresh={() => this.getSummary(coverYear)}
                      filed={filed}
                      year={selectedYear}
                    />
                  </TabPane>
                  <TabPane tab="Expenditures" key="expenditure" forceRender={true}>
                    <BuildingFundExpenditureTable
                     buildingFundId={summaryInfo.buildingFundFilingId}
                     onRefresh={() => this.getSummary(coverYear)}
                     filed={filed}
                     year={selectedYear}
                    />
                  </TabPane>
                </Tabs>
              </>
            }
          </Content>
        }
      </>
    );
  }

  private checkDisableDate = (selected: moment.Moment) => {
    const now = moment();
    const selectedYear = selected.year();
    const nowYear = now.year();

    return (selectedYear >= nowYear && selectedYear > now.add(1, 'year').year());
  }

  private onCoveredYearOnChange = (coverYear: moment.Moment | null) => {
    if (coverYear != null) {
      this.getSummary(coverYear);
    }
  }

  private getSummary = (year: moment.Moment) => {
    this.setState({ loading: true });
    BuildingFundApiService.getBuildingFundSummaryInfo(this.state.committeeId, year.year().toString())
            .then(buildingFundSummary  => {
        if (buildingFundSummary != null) {
          if (buildingFundSummary.filedDate) {
            buildingFundSummary.filedDate = moment(buildingFundSummary.filedDate);
          }
          if (buildingFundSummary.lastUpdatedOn) {
            buildingFundSummary.lastUpdatedOn = moment(buildingFundSummary.lastUpdatedOn);
          }
          if (buildingFundSummary.createdOn) {
            buildingFundSummary.createdOn = moment(buildingFundSummary.createdOn);
          }

          const upcomingYear = year.clone().add(1, 'years');
          buildingFundSummary.dueDate = moment(`01-31-${upcomingYear.year().toString()}`, 'MM-DD-YYYY');

          this.setState({
            summaryInfo: buildingFundSummary,
            coverYear: year,
            showFilingForm: false,
            filed: buildingFundSummary.filingStatusId === FilingStatusType.FILED ||
              buildingFundSummary.filingStatusId === FilingStatusType.AUDITED,
            loading: false
          });
        }
        else {
          this.setState({ coverYear: year, summaryInfo: BuildingFundFilingDTO.create(), showFilingForm: true, loading: false });
        }
      }).catch(() => {
        notification.error({
          message: 'Error getting summary information',
          description: ''
        });
      });
  }

  private fileReport = (buildingFundFilingId: number, coveredYear: number) => {
    Modal.confirm({
      title: 'Are you sure you want to file this State Party Building Fund for the covered year of ' + coveredYear + '?',
      onOk: () => {
        BuildingFundApiService.fileBuildingFund(buildingFundFilingId)
          .then(result => {
            if (result && result.succeeded) {
              notification.success({ message: 'Successfully filed report.' });
              this.getSummary(this.state.coverYear);
            }
            else {
              notification.error({ message: 'Failed to file report.', description: '' });
            }
          });
      }
    });
  }
}

export default BuildingFundsSummary;


