import * as React from 'react';
import { notification, Modal } from 'antd';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import BuildingFundApiService from '../../../api/BuildingFundApiService';
import BuildingFundExpenditureListDTO from '../../../models/BuildingFundExpenditureListDTO';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';

const { confirm } = Modal;

interface BuildingFundExpenditureTableProps {
  buildingFundId: number;
  onRefresh?: () => void;
  filed?: boolean;
  year: string;
}

interface BuildingFundExpenditureTableState {
}

class BuildingFundExpenditureTable extends React.Component<BuildingFundExpenditureTableProps, BuildingFundExpenditureTableState> {
  private dataTable: DataTable<BuildingFundExpenditureTable> | undefined;

  constructor(props: BuildingFundExpenditureTableProps) {
    super(props);

    this.state = {};
  }
  render() {
    const { buildingFundId, year } = this.props;
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Primary(
      'New Expenditure',
      () => HistoryUtil.push(Routes.generate(
        Routes.BUILDINGFUND_EXPENDITURES, { buildingFundId: buildingFundId.toString(), expenditureId: '0', year }
      ))
    ));

    return (
      <>
        <DataTable
          buttonBar={actionButtons}
          columns={this.getTableColumns()}
          fetchData={{
            fetch: function (tableRequest) {
              return BuildingFundApiService.getBuildingFundExpenditureList(tableRequest, buildingFundId);
            },
            failureMessage: 'Failed to retrieve expenditure history'
          }}
          globalSearch={true}
          ref={(element: any) => (this.dataTable = element)}
          serverSide={true}
          styleOptions={{ compact: true, alternatingRowHighlight: true }}
          tableProps={{
            rowKey: 'expenditureId',
            sortDirections: ['ascend', 'descend'],
            locale: { emptyText: 'Currently there are no expenditures to view' }
          }}
          title="Expenditures"
        />
      </>
    );
  }

  private getTableColumns = (): DataTableColumnProps<BuildingFundExpenditureListDTO>[] => {
    const tableColumns: DataTableColumnProps<BuildingFundExpenditureListDTO>[] = [
      DataTableColumnUtil.Date('Date', 'expenditureDate', 100),
      DataTableColumnUtil.Address('Contributor', 'expenditureName',
        (c) => ({
          name: c.expenditureName,
          line1: c.expenditureAddressLine1,
          line2: c.expenditureAddressLine2,
          city: c.expenditureCity,
          state: c.expenditureState,
          zip: c.expenditureZip
        })
      ),
      DataTableColumnUtil.Text('Description', 'description'),
      DataTableColumnUtil.Currency('Amount', 'expenditureAmount'),
    ];

    tableColumns.push(
      DataTableColumnUtil.Buttons('expenditureId',
        [
          {
            text: 'Edit',
            onClick: (rowData) =>
              HistoryUtil.push(Routes.generate(
                Routes.BUILDINGFUND_EXPENDITURES,
                {
                  buildingFundId: this.props.buildingFundId,
                  expenditureId: rowData.expenditureId || '',
                  year: this.props.year
                 }))
          },
          {
            text: 'Delete',
            onClick: (rowData) => this.confirmDelete(
              rowData.expenditureId.toString() || '',
              rowData.expenditureName || '',
              rowData.expenditureAmount || 0)
          }
        ],
        150)
    );

    return tableColumns;
  }

  private showTableAndRefresh = () => {
    if (this.dataTable) {
      this.dataTable.refresh();
    }
  }

  private confirmDelete = (expenditureId: string, expenditureName: string, amount: number) => {
    confirm({
      title: 'Are you sure you want to delete this expenditure?',
      content: 'The following record will be permanently deleted: ' + expenditureName + ' - $' + amount,
      onOk: () => {
        this.deleteExpenditure(expenditureId);
      },
    });
  }

  private deleteExpenditure = (expenditureId: string) => {
    BuildingFundApiService.deleteBuildingFundExpenditure(parseInt(expenditureId))
      .then(() => {
        this.showTableAndRefresh();
        notification.success({
          message: 'Deleted expenditure successfully.'
        });

        if (this.props.onRefresh) {
          this.props.onRefresh();
        }
      }).catch(() => {
        notification.error({
          message: 'Failed to delete expenditure.',
          description: '',
        });
      });
  }
}

export default BuildingFundExpenditureTable;