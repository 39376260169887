import * as React from 'react';
import {
    Button,
    FormInstance,
    notification,
    Space,
    Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import CustomForm from '../../shared/CustomForm';
import PaymentForm from '../../shared/PaymentForm';
import CurrentUser from '../../../utils/CurrentUser';
import BuildingFundApiService from '../../../api/BuildingFundApiService';
import PayeeFormDTO from '../../../models/PayeeFormDTO';
import { RouteComponentProps } from 'react-router';
import Dr2GroupTypes from '../../../consts/Dr2GroupTypes';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import moment from 'moment';
import ContactEditFormDTO from '../../../models/ContactEditFormDTO';
import ContactApiService from '../../../api/ContactApiService';

interface BuildingFundExpenditureFormProps {
    buildingFundId: string;
    expenditureId: string;
    year: string;
}

interface BuildingFundExpenditureFormState {
    payeeFormDTO: PayeeFormDTO;
    loading: boolean;
    committeeId: string;
    expenditureId?: string;
    buildingFundId: string;
    year: string;
}

class BuildingFundExpenditureForm extends React.Component<RouteComponentProps<BuildingFundExpenditureFormProps>, BuildingFundExpenditureFormState> {
    private readonly _formRef = React.createRef<FormInstance>()
    constructor(props: RouteComponentProps<BuildingFundExpenditureFormProps>) {
        super(props);

        this.state =
        {
            payeeFormDTO: PayeeFormDTO.create(
                {
                    Id: parseInt(this.props.match.params.expenditureId ?? '0'),
                    buildingFundFilingId: parseInt(this.props.match.params.buildingFundId ?? '0'),
                    committeeID: CurrentUser.Get()?.committeeId,
                }),
            committeeId: CurrentUser.Get()?.committeeId || '',
            expenditureId: this.props.match.params.expenditureId,
            buildingFundId: this.props.match.params.buildingFundId,
            loading: false,
            year: this.props.match.params.year,
        };
    }

    componentDidMount() {
        this.getExpenditure();
    }

    render() {
        const { payeeFormDTO, loading, expenditureId } = this.state;
        const payeeTypes = [Dr2GroupTypes.COMPANYOTHER];
        return (
            <>
                {!loading &&
                    <Content className="content-pad">
                        <Typography.Title level={4}>Expenditure</Typography.Title>
                        <CustomForm formRef={this._formRef} initialValues={payeeFormDTO} layout="vertical" onFinish={this.handleSave}>
                            <PaymentForm
                                formRef={this._formRef}
                                payeeForm={payeeFormDTO}
                                paymentId={expenditureId?.toString() || ''}
                                payeeTypes={payeeTypes}
                                showInKindIndicator={false} showCheckNum={false} />
                            <Space>
                                <Button type="primary" disabled={loading} htmlType="submit" > Save Expenditure </Button>
                                <Button type="default" disabled={loading} onClick={this.handleCancel}> Cancel </Button>
                            </Space>
                        </CustomForm>
                    </Content>
                }
            </>);

    }

    private getExpenditure = () => {
        this.setState({ loading: true });
        if (this.state.expenditureId != null) {
            BuildingFundApiService.getBuildingFundExpenditure(parseInt(this.state.expenditureId))
                .then(result => {
                    if (result) {
                        if (result.date) {
                            result.date = moment.utc(result.date);
                        }
                        this.setState({ payeeFormDTO: result });
                    }
                    this.setState({ loading: false });
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to get building fund expenditure.',
                        description: ''
                    });
                    this.setState({ loading: false });
                });
        }
    }

    private handleSave = (values: PayeeFormDTO) => {
        this.setState({ loading: true });
        if (this.state.buildingFundId != null && this.state.expenditureId != null) {
            const payeeform = { ...values };
            const contact = ContactEditFormDTO.create();

            payeeform.id = parseInt(this.state.expenditureId);
            payeeform.contributorAutoFillId == '' ? payeeform.contributorAutoFillId = null : payeeform.contributorAutoFillId;
            payeeform.addressLine2 == '' ? payeeform.addressLine2 = null : payeeform.addressLine2;
            payeeform.phoneNumber == '' ? payeeform.phoneNumber = null : payeeform.phoneNumber;

            if (!payeeform.contributorAutoFillId) {
                contact.perorgId = this.state.committeeId;
                contact.contactType = payeeform.payeeType || '';
                contact.contactName = payeeform.payee || '';
                contact.addressLine1 = payeeform.addressLine1 || '';
                contact.addressLine2 = payeeform.addressLine2 || '';
                contact.addressCity = payeeform.city || '';
                contact.addressState = payeeform.state || '';
                contact.addressZip = payeeform.zip || '';
                contact.email = '';
                contact.phoneNumber = payeeform.phoneNumber || '';
                contact.relationshipCode = payeeform.relationshipCode || '';
                contact.trustee = payeeform.trustee || '';
                contact.trustor = payeeform.trustor || '';
            }

            BuildingFundApiService.saveBuildingFundExpenditure(payeeform, this.state.committeeId, parseInt(this.state.buildingFundId))
                .then(result => {
                    if (result.succeeded) {
                        !payeeform.contributorAutoFillId ? ContactApiService.save(contact, this.state.committeeId) : null;
                        notification.success({
                            message: 'Expenditure saved successfully.',
                            description: ''
                        });
                        HistoryUtil.push(Routes.generate(Routes.BUILDINGFUNDS_SUMMARY), { year: this.state.year });
                    }
                    else {
                        notification.error({
                            message: 'Expenditure failed to save.',
                            description: ''
                        });
                    }
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to save expenditure.',
                        description: ''
                    });
                });
        }

        this.setState({ loading: false });
    }

    private handleCancel = () => {
        HistoryUtil.push(Routes.generate(Routes.BUILDINGFUNDS_SUMMARY), { year: this.state.year });
    }
}

export default BuildingFundExpenditureForm;