import * as React from 'react';
import {
    Button,
    FormInstance,
    notification,
    Space,
    Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import CustomForm from '../../shared/CustomForm';
import PaymentForm from '../../shared/PaymentForm';
import CurrentUser from '../../../utils/CurrentUser';
import BuildingFundApiService from '../../../api/BuildingFundApiService';
import PayeeFormDTO from '../../../models/PayeeFormDTO';
import { RouteComponentProps } from 'react-router';
import Dr2GroupTypes from '../../../consts/Dr2GroupTypes';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../consts/Routes';
import moment from 'moment';
import ContactEditFormDTO from '../../../models/ContactEditFormDTO';
import ContactApiService from '../../../api/ContactApiService';

interface BuildingFundContributionFormProps
{
    buildingFundId: string;
    contributionId: string;
    year: string;
}

interface BuildingFundContributionFormState
{
    payeeFormDTO: PayeeFormDTO;
    loading: boolean;
    committeeId: string;
    contributionId?: string;
    buildingFundId: string;
    year: string;
}

class BuildingFundContributionForm extends React.Component<RouteComponentProps<BuildingFundContributionFormProps>,
    BuildingFundContributionFormState> {
    private readonly _formRef = React.createRef<FormInstance>()
    constructor(props: RouteComponentProps<BuildingFundContributionFormProps>) {
        super(props);

        this.state =
        {
            payeeFormDTO: PayeeFormDTO.create(
                {
                    Id : parseInt(this.props.match.params.contributionId ?? '0'),
                    buildingFundFilingId: parseInt(this.props.match.params.buildingFundId ?? '0'),
                    committeeID: CurrentUser.Get()?.committeeId,
                }),
            committeeId: CurrentUser.Get()?.committeeId || '',
            contributionId: this.props.match.params.contributionId,
            buildingFundId: this.props.match.params.buildingFundId,
            year: this.props.match.params.year,
            loading: false,
        };
    }

    componentDidMount() {
        this.getContribution();
    }

    render() {
        const { payeeFormDTO, loading, contributionId} = this.state;
        const contributorTypes =  [ Dr2GroupTypes.COMPANYOTHER ];
        return (
        <>
            {!loading &&
                <Content className="content-pad">
                    <Typography.Title level={4}>Contributions</Typography.Title>
                    <CustomForm formRef={this._formRef} initialValues={payeeFormDTO} layout="vertical" onFinish={this.handleSave}>
                        <PaymentForm 
                            formRef={this._formRef}
                            payeeForm={payeeFormDTO}
                            paymentId={contributionId?.toString() || ''}
                            payeeTypes={contributorTypes}
                            showInKindIndicator={false} showCheckNum={false} />
                        <Space>
                            <Button type="primary" disabled={loading} htmlType="submit" > Save Contribution </Button>
                            <Button type="default" disabled={loading} onClick={this.handleCancel}> Cancel </Button>
                        </Space>
                    </CustomForm>
                </Content>
            }
        </>);

    }

    private getContribution = () => {
        this.setState({loading: true});
        if (this.state.contributionId != null){
            BuildingFundApiService.getBuildingFundContribution( parseInt(this.state.contributionId))
                .then(result => {
                    if (result) {
                        if (result.date)
                        {
                            result.date = moment.utc(result.date);
                        }
                        this.setState({ payeeFormDTO: result });
                    }
                    this.setState({ loading: false });
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to get building fund contribution.',
                        description: ''
                    });
                    this.setState({ loading: false });
                });
        }
    }

    private handleSave = (values: PayeeFormDTO) => {
        this.setState({ loading: true });
        if (this.state.buildingFundId != null && this.state.contributionId != null) {
            const payeeform = { ...values };
            const contact = ContactEditFormDTO.create();

            payeeform.id = parseInt(this.state.contributionId);
            payeeform.contributorAutoFillId == '' ? payeeform.contributorAutoFillId = null : payeeform.contributorAutoFillId;
            payeeform.addressLine2 == '' ? payeeform.addressLine2 = null : payeeform.addressLine2;
            payeeform.phoneNumber == '' ? payeeform.phoneNumber = null : payeeform.phoneNumber;

            if (!payeeform.contributorAutoFillId) {
                contact.perorgId = this.state.committeeId;
                contact.contactType = payeeform.payeeType || '';
                contact.contactName = payeeform.payee || '';
                contact.addressLine1 = payeeform.addressLine1 || '';
                contact.addressLine2 = payeeform.addressLine2 || '';
                contact.addressCity = payeeform.city || '';
                contact.addressState = payeeform.state || '';
                contact.addressZip = payeeform.zip || '';
                contact.email = '';
                contact.phoneNumber = payeeform.phoneNumber || '';
                contact.relationshipCode = payeeform.relationshipCode || '';
                contact.trustee = payeeform.trustee || '';
                contact.trustor = payeeform.trustor || '';
            }
            
            BuildingFundApiService.saveBuildingFundContribution(payeeform, this.state.committeeId, parseInt(this.state.buildingFundId))
                .then(result => {
                    if (result.succeeded) {
                        !payeeform.contributorAutoFillId ? ContactApiService.save(contact, this.state.committeeId) : null;
                        notification.success({
                            message: 'Contribution saved successfully.',
                            description: ''
                        });
                        HistoryUtil.push(Routes.generate(Routes.BUILDINGFUNDS_SUMMARY), { year: this.state.year });
                    }
                    else {
                        notification.error({
                            message: 'Contribution failed to save.',
                            description: ''
                        });
                    }
                })
                .catch(() => {
                    notification.error({
                        message: 'Failed to save contribution.',
                        description: ''
                    });
                });
        }

        this.setState({ loading: false });
    }

    private handleCancel = () => {
        HistoryUtil.push(Routes.generate(Routes.BUILDINGFUNDS_SUMMARY), { year: this.state.year });
    }
}

export default BuildingFundContributionForm;