import { Typography } from 'antd';
import * as React from 'react';
const { Link } = Typography;

const { Paragraph, Title } = Typography;

interface StartPfdProps {
}

interface StartPfdState {
}

class StartPfd extends React.Component<StartPfdProps, StartPfdState> {

  constructor(props: StartPfdProps) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <>
        < Title level={4} >
          Welcome to the Ethics Board&apos;s Web Reporting System for Personal Financial Disclosure Statements
        </Title>
        <Paragraph>
          Iowa Code section&nbsp;
          <Link href="https://www.legis.iowa.gov/docs/code/68b.35.pdf" target="_blank">68B.35</Link>
          &nbsp;requires certain executive branch employees and officials to file personal
          financial disclosure statements with the Iowa Ethics and Campaign Disclosure Board. These statements
          are due on or before April 30 of each year following a year during which the person holds a designated
          position, without regard to the length of time the position was occupied by the person.
          A person who held a designated position who leaves that position has a continuing obligation to file the
          statement for any year or portion of a year in which the position was held prior to termination. These
          statements are public records and available on the Ethics Board&apos;s website.
        </Paragraph>
        <Paragraph>
          It shall be considered a violation of Iowa Code section&nbsp;
          <Link href="https://www.legis.iowa.gov/docs/code/68b.35.pdf" target="_blank">68B.35</Link>
          &nbsp;for an individual holding a designated position in the executive branch to file a disclosure statement
          containing false or fraudulent information. If the Ethics Board determines after a contested case proceeding
          that a false or fraudulent disclosure statement was filed, the Ethics Board may impose any of the actions
          under Iowa Code section&nbsp;
          <Link href="https://www.legis.iowa.gov/docs/code/68b.32d.pdf" target="_blank">68B.32D</Link>.
        </Paragraph>
        <Paragraph>
          Filers should review the Board&apos;s&nbsp;
           <Link href="https://ethics.iowa.gov/frequently-asked-questions/personal-financial-disclosure-faqs" target="_blank">FAQs</Link>
          &nbsp;before filing their statements. Contact the Ethics Board&apos;s staff at (515) 281-4028 if you need assistance.
        </Paragraph>
      </>
    );
  }
}

export default StartPfd;
